import React, { useEffect, useState } from "react"

import "../styles/bootstrap.min.css"
import "./postView.css"
import SEO from "../components/seo"
import moment from 'moment';

const url = 'https://api.plaiz.io';

const PostViewPage = () => {

    const [ user, setUser ] = useState(null);

    useEffect(() => {
      loadData();
    }, []);

    const loadData = async () => {
      try {
        const postId = window.location.href.split('=').slice(-1)[0];
        const res = await fetch(`${url}/api/posts/${postId}`);
        const data = await res.json();
        setUser(data);
      } catch (err) {
        console.error('Error loading data', err);
      }
    }

    return (
      <div className="post-page">
            <SEO title="Plaiz - Le Réseau Social De La Mode - Publication"/>
            <div className="post-container">
                <div className="postView-info">
                    {user && <img className="postView-profilepic" src={`https://api.plaiz.io/images/${user.picture}`}/>}
                    <div className="postView-username-date">
                        {!!user && <p className="postView-username">{user.username}</p>}
                        {!!user && <p className="postView-date">{moment(user.created_at).format('DD/MM/YYYY')}</p>}
                    </div>
                </div>
                {!!user && <img className="postView-photo" src={`https://api.plaiz.io/images/${user.picture}`}/>}
                <p className="footer-text-post-view">
                  A propos: Plaiz est un anti-réseau social pour les amateurs et passionnés de mode, sur lequel on est tous la version la plus audacieuse de nous-mêmes. On met en avant la créativité plutôt que la popularité, donc chez nous, pas de compteurs de likes, pas de followers, pas de prise de tête, pas de jugement.
                </p>
            </div>
            <a target="_blank" href="https://plaiz.onelink.me/kHUQ/postlink" className="download-button">
                <p className="download-text">Télécharger Plaiz 🌹</p>
            </a>
        </div>
    )

}

export default PostViewPage
